export const Language = {
  en: {
    title_text: "Select Language",
    subtitle_text: "Please select the requested language",
    //language_text: "Language",
    privacy_agree_text:
      'I have read the <a href="./privacyStatement">privacy statement & terms of use</a> and I agree',
    next_button_statement:
      'Please click on the checkbox next to the statement "I have read your privacy statement" to proceed.',
    imprint_button: "imprint",
  },
  de: {
   // title_text: "Willkommen zu Ihrer Digitalen Einweisung Arbeitssicherheit",
    // subtitle_text:
    //   "Bitte wählen Sie die gewünschte Sprache, bevor Sie fortfahren",
    //language_text: "Sprache",
    privacy_agree_text:
      'Ich habe die <a>Datenschutzhinweise & Nutzungsbedingungen</a> gelesen und bin damit einverstanden.',

    next_button_statement:
      'Bitte klicken Sie zum Fortfahren auf das Kontrollkästchen neben der Erklärung "Ich habe die Datenschutzhinweise & Nutzungsbedingungen gelesen ...".',
    imprint_button: "Impressum",
  },
  du: {
    title_text: "Selecteer taal",
    subtitle_text: "Selecteer de gevraagde taal",
    //language_text: "Taal",
    privacy_agree_text:
      'Ik heb de <a href="./privacyStatement"> privacyverklaring en gebruiksvoorwaarden </a> gelzen en ik ga akkoord',
    next_button_statement:
      'Klik op het selectievakje naast de stelling "Ik heb uw privacyverklaring gelzen" om verder te gaan.',
    imprint_button: "afdruk",
  },
  hu: {
    title_text: "Nyelv kiválasztása",
    subtitle_text: "Kérjük, válassza ki a kívánt nyelvet",
    //language_text: "Nyelv",
    privacy_agree_text:
      'Elolvastam az <a href="./privacyStatement"> adatvédelmi nyilatkozatot és a felhasználási feltételeket </a>, és elfogadom',

    next_button_statement:
      'A folytatáshoz kattintson az "Elolvastam az adatvédelmi nyilatkozatát" nyilatkozat melletti jelölőnégyzetre.',
    imprint_button: "imprint",
  },
  fr: {
    title_text: "Sélectionnez la langue",
    subtitle_text: "Veuillez sélectionner la langue souhaitée",
    //language_text: "La langue",
    privacy_agree_text:
      "J'ai lu <a href='./privacyStatement'>la déclaration de confidentialité et les conditions d'utilisation</a> et je les accepte",

    next_button_statement:
      "Veuillez cliquer sur la case à cocher à côté de la déclaration J'ai lu votre déclaration de confidentialité pour continuer.",
    imprint_button: "mentions légales",
  },
  es: {
    title_text: "Seleccione el idioma",
    subtitle_text: "Por favor seleccione el idioma solicitado",
    //language_text: "Lenguaje",
    privacy_agree_text:
      "<a href='./privacyStatement'>He leído la declaración de privacidad y los términos de uso y estoy de acuerdo</a>",

    next_button_statement:
      "Por favor haga clic en la casilla de verificación junto a la declaración He leído su declaración de privacidad para proceder..",
    imprint_button: "Imprimir",
  },
  pt: {
    title_text: "Seleccionar Língua",
    subtitle_text: "Por favor seleccione a língua solicitada",
    //language_text: "Língua",
    privacy_agree_text:
      "<a href='./privacyStatement'>Li a declaração de privacidade e os termos de utilização e concordo</a>",

    next_button_statement:
      "Por favor clique na caixa de verificação ao lado da declaração que li para prosseguir..",
    imprint_button: "imprimir",
  },
  it: {
    title_text: "Selezionare la lingua",
    subtitle_text: "Si prega di selezionare la lingua richiesta",
    //language_text: "Lingua",
    privacy_agree_text:
      "<a href='./privacyStatement'>Ho letto l'informativa sulla privacy e le condizioni d'uso e accetto</a>",

    next_button_statement:
      "Per favore cliccate sulla casella di controllo accanto alla dichiarazione Ho letto la vostra dichiarazione sulla privacy per procedere",
    imprint_button: "impronta",
  },
  po: {
    title_text: "Wybierz język",
    subtitle_text: "Proszę wybrać żądany język",
    //language_text: "Język",
    privacy_agree_text:
      "<a href='./privacyStatement'>Przeczytałem oświadczenie o ochronie prywatności i warunki użytkowania i zgadzam się z nimi</a>",

    next_button_statement:
      "Proszę kliknąć na pole wyboru obok oświadczenia które przeczytałem w oświadczeniu o ochronie prywatności aby kontynuować",
    imprint_button: "odcisk .",
  },
  ru: {
    title_text: "Выберите Язык",
    subtitle_text: "Пожалуйста выберите желаемый язык",
    //language_text: "Язык",
    privacy_agree_text:
      "Я прочитал <a href='./privacyStatement'>заявление о конфиденциальности и условия использования</a> и соглашаюсь с ними",

    next_button_statement:
      "Пожалуйста нажмите на флажок рядом с заявлением о конфиденциальности которое я прочитал чтобы продолжить..",
    imprint_button: "напечатать",
  },
  bg: {
    title_text: "Избери език",
    subtitle_text: "Моля изберете искания език",
    //language_text: "Език",
    privacy_agree_text:
      "<a href='./privacyStatement'>Прочетох декларацията за поверителност и условията за ползване и се съгласявам</a>",

    next_button_statement:
      "Моля щракнете върху квадратчето до изявлението че съм прочел декларацията ви за поверителност за да продължите.",
    imprint_button: "отпечатък",
  },
  gr: {
    title_text: "Επιλέξτε γλώσσα",
    subtitle_text: "Παρακαλώ επιλέξτε τη ζητούμενη γλώσσα",
    //language_text: "Γλώσσα",
    privacy_agree_text:
      "<a href='./privacyStatement'>Έχω διαβάσει τη δήλωση απορρήτου και τους όρους χρήσης και συμφωνώ</a>",

    next_button_statement:
      "Κάντε κλικ στο πλαίσιο ελέγχου δίπλα στη δήλωση που έχω διαβάσει τη δήλωση απορρήτου σας για να συνεχίσω.αποτύπωμα",
    imprint_button: "undefined",
  },
  hu: {
    title_text: "Select Language",
    subtitle_text: "Please select the requested language",
    //language_text: "Language",
    privacy_agree_text:
      'I have read the <a href="./privacyStatement">privacy statement & terms of use</a> and I agree',

    next_button_statement:
      'Please click on the checkbox next to the statement "I have read your privacy statement" to proceed.',
    imprint_button: "imprint",
  },
  tr: {
    title_text: "Dil Seçin",
    subtitle_text: "Lütfen istenen dili seçin",
    //language_text: "Dil",
    privacy_agree_text:
      "<a href='./privacyStatement'>Gizlilik bildirimini ve kullanım koşullarını okudum ve kabul ediyorum</a>",

    next_button_statement:
      "Devam etmek için lütfen gizlilik bildiriminizi okudum ifadenin yanındaki onay kutusuna tıklayın.",
    imprint_button: "baskı",
  },
  cz: {
    title_text: "Zvolte jazyk",
    subtitle_text: "Vyberte požadovaný jazyk",
    //language_text: "Jazyk",
    privacy_agree_text:
      "<a href='./privacyStatement'>Přečetl jsem si prohlášení o ochraně osobních údajů a podmínky použití a souhlasím</a>",

    next_button_statement:
      "Pokračujte kliknutím na zaškrtávací políčko vedle prohlášení. Přečetl jsem si vaše prohlášení o ochraně osobních údajů.",
    imprint_button: "otisk",
  },
  ro: {
    title_text: "Selecteaza limba",
    subtitle_text: "Vă rugăm să selectați limba solicitată",
    //language_text: "Limba",
    privacy_agree_text:
      "<a href='./privacyStatement'>Am citit declarația de confidențialitate și termenii de utilizare și sunt de acord</a>",

    next_button_statement:
      "Faceți clic pe caseta de selectare de lângă declarația Am citit declarația dvs. de confidențialitate pentru a continua.",
    imprint_button: "imprima",
  },
  rshr: {
    title_text: "Изаберите језик",
    subtitle_text: "Изаберите тражени језик",
    //language_text: "Језик",
    privacy_agree_text:
      "<a href='./privacyStatement'>Прочитао сам изјаву о приватности и услове коришћења и слажем се</a>",

    next_button_statement:
      "Кликните на поље за потврду поред изјаве Прочитао сам вашу изјаву о приватности да бисте наставили.",
    imprint_button: "отисак",
  },
};

// export const Language = {
//     en:{},
//     de:{},
//     du:{},
//     hu:{},
//     fr:{},
//     es:{},
//     pt:{},
//     it:{},
//     po:{},
//     ru:{},
//     bg:{},
//     gr:{},
//     hu:{},
//     tr:{},
//     cz:{},
//     ro:{},
//     rshr:{},
// }