import React, { useEffect, useState, useReducer } from "react";
import { PostMaker, GetMaker } from "../../repository/RequestMaker";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Endpoints } from "../../repository/Endpoints";
import Logout from "../../utils/sharedComponents/Logout";

const Quiz = () => {
  //inits
  const history = useHistory();
  const [questions, setQuestions] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isChecking, setIsChecking] = useState(false);
  const material_id = localStorage.getItem("material_id");
  var que_ids = [];
  var ans_response = [];
  var main_response = {
    enrollment_id: localStorage.getItem("enrollment_id"),
    test_id: material_id,
    answers: [],
  };
  //logics
  useEffect(() => {
    localStorage.removeItem("someIncorrect");
    window.scrollTo(0, 0);
    GetMaker(Endpoints.quiz + material_id).then((res) => {
      setQuestions(shuffle(res.data.questions.slice(0, 5)));
      localStorage.setItem("test_id", res.data.test.id);
      setLoading(false);
    });
  }, []);
  const onSubmit = (e) => {
    e.preventDefault();
    if (allQuestionsAttempted(que_ids)) {
      setIsChecking(true);
      for (let index = 0; index < que_ids.length; index++) {
        const que_id = que_ids[index];
        const ans_ids = collectAnswers(que_id);
        //console.log(index, "ans_ids", ans_ids);
        if (index + 1 === que_ids.length) {
          checkAnswers(que_id, ans_ids, true);
        } else {
          checkAnswers(que_id, ans_ids, false);
        }
      }
      //main_response.test_id = questions[0].id;
      main_response.answers = JSON.stringify(ans_response);
      //console.log("main_response", main_response);
    } else {
      alert("Attempt all  Questions");
    }
  };

  const postSubmit = () => {
    if (localStorage.getItem("someIncorrect")) {
      console.log("some", localStorage.getItem("someIncorrect"));
      // window.location.reload();
      // forceUpdate();
      setTimeout(() => {
        alert("Nicht alle Antworten sind richtig");
        let attempt = parseInt(localStorage.getItem("attempt"));
        console.log("attempt:", attempt);
        attempt = attempt + 1;
        localStorage.setItem("attempt", attempt);
        if (attempt > 1) {
          alert("Bitte wiederholen Sie den Kurs");
          history.push({
            pathname: "/courses",
          });
          return;
        }
        reloadQuestions();
      }, 2000);
    } else {
      console.log("main_response", main_response);

      setTimeout(() => {
        main_response.test_id = localStorage.getItem("test_id");
        PostMaker(main_response, Endpoints.submit_answers).then((res) => {
          //console.log(res);
          PostMaker(
            {
              section_material_id: material_id,
              course_enrollment_id: localStorage.getItem("enrollment_id"),
            },
            Endpoints.material_progress
          ).then((response) => {
            const current_material = parseInt(
              localStorage.getItem("current_material")
            );
            localStorage.setItem("current_material", current_material + 1);
            history.push({
              pathname: "/material",
            });
          });
        });
      }, 2000);
    }
  };

  const collectAnswers = (que_id) => {
    var ans_dictionary = {
      q_id: "",
      ans: [],
    };
    ans_dictionary.q_id = que_id;

    var checked_ans = [];
    var checkboxes = document.getElementsByName("answer_checkbox" + que_id);
    var checkboxesChecked = [];
    // loop over them all
    for (var i = 0; i < checkboxes.length; i++) {
      // And stick the checked ones onto an array...
      if (checkboxes[i].checked) {
        checkboxesChecked.push(checkboxes[i]);
      }
    }
    for (let index = 0; index < checkboxesChecked.length; index++) {
      const element = checkboxesChecked[index];
      checked_ans.push(element.value);
    }
    ans_dictionary.ans = checked_ans;
    //setAnsResponse(ans_dictionary);
    ans_response.push(ans_dictionary);
    return checked_ans;
  };

  const checkAnswers = (q_id, a_ids, isLast) => {
    PostMaker(
      {
        question_id: q_id,
        answers: JSON.stringify(a_ids),
      },
      Endpoints.check_answers
    ).then((res) => {
      const correct_ans = res.data.correct_answers;
      for (let i = 0; i < a_ids.length; i++) {
        const a_id = a_ids[i];
        if (correct_ans.includes(a_id)) {
          document.getElementById(a_id).innerHTML =
            '<i class="fa fa-check" aria-hidden="true"></i>';
        } else {
          localStorage.setItem("someIncorrect", true);
          document.getElementById(a_id).innerHTML =
            '<i class="fa fa-times" aria-hidden="true"></i>';
        }

        var cusid_ele = document.getElementsByName(q_id);
        for (let i = 0; i < cusid_ele.length; ++i) {
          var item = cusid_ele[i];
          item.innerHTML = "";
        }

        if (isLast) {
          postSubmit();
        }
      }
    });
  };

  const reloadQuestions = () => {
    setQuestions([]);
    setLoading(true);
    setIsChecking(false);
    que_ids = [];
    ans_response = [];
    main_response.answers = [];
    localStorage.removeItem("someIncorrect");
    GetMaker(Endpoints.quiz + material_id).then((res) => {
      setQuestions(shuffle(res.data.questions.slice(0, 5)));
      localStorage.setItem("test_id", res.data.test.id);
      setLoading(false);
    });
  };

  function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  const allQuestionsAttempted = (que_ids) => {
    var allAttempted = true;
    for (let index = 0; index < que_ids.length; index++) {
      const que_id = que_ids[index];
      const checkbox_c = [];
      var checkboxes = document.getElementsByName("answer_checkbox" + que_id);
      for (let i = 0; i < checkboxes.length; i++) {
        const checkbox = checkboxes[i];
        if (checkbox.checked) {
          //console.log("answer_checkbox" + que_id + "checked");
          checkbox_c.push(true);
        } else {
          //console.log("answer_checkbox" + que_id + "not checked");
        }
      }
      if (checkbox_c.length == 0) {
        allAttempted = false;
      }
    }
    return allAttempted;
  };
  return (
    <div>
      {isLoading ? (
        <>
          <Spinner animation="grow" />
        </>
      ) : (
        <div
          className="bg-mine"
          style={{ marginTop: "20px", padding: "30px", marginBottom: "70px" }}
        >
          {questions.map((question, key) => {
            que_ids.push(question.id);
            return (
              <div name={"questionContainer" + key}>
                <h6
                  style={{
                    fontSize: "1.6rem",
                    fontWeight: "300",
                    lineHeight: "1.2",
                  }}
                  id="title_text"
                >
                  {question.question_body}
                </h6>
                {question.answers.map((answer, anskey) => {
                  return (
                    <div name="answerContainer">
                      <div className="row">
                        <div className="col-2 d-flex justify-content-center">
                          <span id={answer.id}></span>
                          <label className="toggle-control" name={question.id}>
                            <input
                              type="checkbox"
                              name={"answer_checkbox" + question.id}
                              value={answer.id}
                            />
                            <span className="control"></span>
                          </label>
                        </div>
                        <div className="col-10">{answer.answer_body}</div>
                      </div>
                    </div>
                  );
                })}

                <hr />
              </div>
            );
          })}
          <div className="d-flex justify-content-end">
            {isChecking ? (
              <Spinner animation="grow" />
            ) : (
              <button className="btn-smol" onClick={onSubmit}>
                <i class="fa fa-check" aria-hidden="true"></i>
              </button>
            )}
          </div>
        </div>
      )}
      {/* <a className="footer" href="./src/pages/privacyStatement/impressum.html">
        <p id="imprint_button">Impressum</p>
      </a> */}
      <Logout />
    </div>
  );
};

export default Quiz;
