import React, { useEffect, useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Endpoints } from "../../repository/Endpoints";
import { GetMaker } from "../../repository/RequestMaker";
import { Translations } from "../../repository/Translations";
import { Spinner } from "react-bootstrap";
import { Language } from "./Language";
import Logo from "../../utils/sharedComponents/Logo";
import ImprintFooter from "../../utils/sharedComponents/ImprintFooter";
const Home = () => {
  // inits
  const history = useHistory();
  const [languageAvailable, setLanguageAvailable] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [langToggled, setLangToggled] = useState(false);
  const [cookieChecked, setCookieChecked] = useState(false);
  // logics
  const onNext = (e) => {
    e.preventDefault();
    if (!cookieChecked) {
      alert(
        'Bitte klicken Sie zum Fortfahren auf das Kontrollkästchen neben der Erklärung "Ich habe die Datenschutzhinweise & Nutzungsbedingungen gelesen ...".'
      );
    } else {
      history.push({
        pathname: "/userdata",
      });
    }
  };

  // const setLanguage = (id) => {
  //   setLangToggled(true);
  //   console.log("selected language", id);
  //   id = id.split(" ");
  //   localStorage.setItem("language_id", id[0]);
  //   localStorage.setItem("language", id[1]);
  //   Translations(id[1], Language);
  // };

  useEffect(() => {
    localStorage.clear();
    window.scrollTo(0, 0);
    // localStorage.setItem("tenant_id", "52eef02e-65c1-4c44-bb28-e6f8993fdd2e");//Steam & Power
    localStorage.setItem("tenant_id", "b3363583-9cfe-467a-8304-b056e74872c2");
    localStorage.setItem("language", "de");
    localStorage.setItem("language_id", "1");

    // GetMaker(Endpoints.list_languages).then((res) => {
    //   //console.log(res.data);
    //   setLanguages(res.data);
    //   setLanguageAvailable(true);
    // });
  }, []);

  useLayoutEffect(() => {
    Translations("de", Language);
  }, []);
  // render
  return (
    <>
      <div className="bg-mine">
        <h1 className="display-4" id="title_text">
          Willkommen zu Ihrer
        </h1>
        <h1 className="display-4" id="title_text">
          Digitalen Einweisung
        </h1>
        <h1 className="display-4" id="title_text">
          Arbeitssicherheit
        </h1>

        <hr className="my-4" />
        <div>
          <br />

          <div className="d-flex flex-row">
            {/* <input
              className="custom-control-input"
              type="checkbox"
              name="cookieMaker"
              id="cookieMaker"
              onChange={(e) => setCookieChecked(e.currentTarget.checked)}
            /> */}

            <label className="toggle-control">
              <input
                type="checkbox"
                onChange={(e) => setCookieChecked(e.currentTarget.checked)}
              />
              <span className="control"></span>
            </label>
            <span className="margin_left"></span>
            <label
              className="custom-control-label"
              id="privacy_agree_text"
              for="cookieMaker"
              style={{ marginLeft: "30px", marginTop: "-3px" }}
            >
              Ich habe die Datenschutzhinweise & Nutzungsbedingungen gelesen und
              bin damit einverstanden.
            </label>
          </div>
        </div>
        <div>
          <div className="margin_top"></div>
          <button
            // className="btn btn-outline-dark btn-lg btn-round"
            className="btn-round"
            id="next_button"
            onClick={onNext}
          >
            <i class="fa fa-forward" aria-hidden="true"></i>
          </button>
          <p className="form-text text-muted" id="next_button_statement">
            Bitte klicken Sie zum Fortfahren auf das Kontrollkästchen neben der
            Erklärung "Ich habe die Datenschutzhinweise & Nutzungsbedingungen
            gelesen ...".
          </p>
        </div>

        <a>
          <ImprintFooter>
            <span id="imprint_button">Impressum</span>{" "}
          </ImprintFooter>
        </a>
      </div>
    </>
  );
};

export default Home;
