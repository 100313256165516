import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Endpoints } from "../../repository/Endpoints";
import Logout from "../../utils/sharedComponents/Logout";
import { Spinner } from "react-bootstrap";
import {
  PostMaker,
  StreamMaker,
  SubtitleStreamMaker,
} from "../../repository/RequestMaker";

const PLAYING_DEBOUNCE_TIME = 50;
const WAITING_DEBOUNCE_TIME = 200;

const Training = () => {
  //inits
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [vidUrl, setVidUrl] = useState("");
  const [subUrl, setSubUrl] = useState("");
  const vidRef = useRef(null);
  const material_id = localStorage.getItem("material_id");

  const [isPlaying, setIsPlaying] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const isWaitingTimeout = useRef(null);
  const isPlayingTimeout = useRef(null);
  const videoElementRef = useRef();
  //logics
  const toNext = (e) => {
    e.preventDefault();
    PostMaker(
      {
        section_material_id: material_id,
        course_enrollment_id: localStorage.getItem("enrollment_id"),
      },
      Endpoints.material_progress
    ).then((response) => {
      const current_material = parseInt(
        localStorage.getItem("current_material")
      );
      localStorage.setItem("current_material", current_material + 1);
      history.push({
        pathname: "/material",
      });
    });
  };
  const playVideo = (e) => {
    vidRef.current.play();
  };
  const pauseVideo = (e) => {
    vidRef.current.pause();
  };

  useEffect(() => {
    //   window.scrollTo(0, 0);
    const material_id = localStorage.getItem("material_id");
    StreamMaker(
      Endpoints.education_section_materials_get_video_pseudo_stream +
        material_id
    ).then((res) => {
      setVidUrl(res);
      setIsLoading(false);
      setTimeout(() => {
        videoElementRef.current.play();
      }, 100);
    });
  }, []);

  useEffect(() => {
    if (!videoElementRef.current) {
      return;
    }

    const element = videoElementRef.current;

    element.addEventListener("waiting", waitingHandler);
    element.addEventListener("play", playHandler);
    element.addEventListener("playing", playHandler);
    element.addEventListener("pause", pauseHandler);

    // clean up
    return () => {
      clearTimeout(isWaitingTimeout.current);
      clearTimeout(isPlayingTimeout.current);

      element.removeEventListener("waiting", waitingHandler);
      element.removeEventListener("play", playHandler);
      element.removeEventListener("playing", playHandler);
      element.removeEventListener("pause", pauseHandler);
    };
  }, [videoElementRef]);

  const waitingHandler = () => {
    clearTimeout(isWaitingTimeout.current);

    isWaitingTimeout.current = setTimeout(() => {
      setIsWaiting(true);
    }, WAITING_DEBOUNCE_TIME);
  };

  const playHandler = () => {
    clearTimeout(isWaitingTimeout.current);
    clearTimeout(isPlayingTimeout.current);

    isPlayingTimeout.current = setTimeout(() => {
      setIsPlaying(true);
      setIsWaiting(false);
    }, PLAYING_DEBOUNCE_TIME);
  };

  const pauseHandler = () => {
    clearTimeout(isWaitingTimeout.current);
    clearTimeout(isPlayingTimeout.current);

    isPlayingTimeout.current = setTimeout(() => {
      setIsPlaying(false);
      setIsWaiting(false);
    }, PLAYING_DEBOUNCE_TIME);
  };

  const handlePlayPauseClick = () => {
    if (videoElementRef.current) {
      if (isPlaying) {
        videoElementRef.current.pause();
      } else {
        videoElementRef.current.play();
      }
    }
  };
  return (
    <div>
      {isLoading ? (
        <div
          className="d-flex justify-content-center"
          style={{
            zIndex: 999,
            height: "100vh",
            width: "100vw",
            position: "absolute",
            alignItems: "center",
            alignContent: "center",
            top: 0,
            left: 0,
            marginTop: -60,
          }}
        >
          <Spinner animation="grow" />
        </div>
      ) : (
        <></>
      )}
      <div className="bg-mine">
        <div className="embed-responsive embed-responsive-16by9">
          <>
            <video
              id="myVideo"
              // crossorigin="anonymous"
              className="embed-responsive-item"
              ref={videoElementRef}
              // src={localStorage.getItem("video_url")}
              src={vidUrl}
              style={{ width: "100%", height: "auto", borderRadius: "20px" }}
              autoPlay
            >
              {/* <source src={vidUrl} type="video/mp4" /> */}
              {/* <track
                  label="English"
                  kind="subtitles"
                  srcLang="en"
                  src={subUrl}
                  // src={localStorage.getItem("subtitle_url")}
                  default
                /> */}
              Your browser does not support the video tag.
            </video>

            {/* buttons */}
            <div className="margin_top"></div>
            <div class="d-flex bd-highlight mb-3">
              <div class="p-2 bd-highlight">
                <button
                  onClick={handlePlayPauseClick}
                  className="btn-smol btn-media"
                >
                  {isPlaying ? (
                    <i className="fa fa-pause"></i>
                  ) : (
                    <i className="fa fa-play"></i>
                  )}
                  {isWaiting && (
                    <span className="SimpleVideo-loader">Buffering</span>
                  )}
                </button>
              </div>
              <div class="ms-auto p-2 bd-highlight">
                {isLoading ? (
                  <></>
                ) : (
                  <button
                    id="pause_button"
                    //className="btn btn-outline-dark"
                    className="btn-round"
                    onClick={toNext}
                  >
                    <i class="fa fa-forward" aria-hidden="true"></i>
                  </button>
                )}
              </div>
            </div>
          </>
        </div>
      </div>
      <Logout />
    </div>
  );
};

export default Training;
